@import url(https://fonts.googleapis.com/css?family=Roboto+Slab|Roboto:400,500&display=swap);
/*font-family: 'Roboto', sans-serif;
font-family: 'Roboto Slab', serif;*/
* {
  box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}
a {
    color: inherit;
    text-decoration: none;
}

.home-container {
    max-width: 1440px;
    margin:0 auto;
}
.gray-section {
    background-color: #F6F7FB;
    
}
